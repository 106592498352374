/* @import url("http://fonts.cdnfonts.com/css/google-sans"); */
body {
  margin: 0;
  font-family: "Poppins", sans-serif;
}

*::-webkit-scrollbar {
  display: block;
}

::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #dedede;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #f5f5f5;
  border-radius: 8px;
}

/* @font-face {
  font-family: "Product Sans Regular";
  src: local("Product Sans Regular"),
   url("./fonts/Product Sans Regular.ttf") format("truetype");
  
  } */
